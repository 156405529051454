export const navigations = [
    {name: 'Dashboard', path: '/auth/dashboard', icon: 'dashboard', children: []},

    {
        name: 'CRM',
        icon: 'people',
        children: [
            {
                name: 'Utilizadores',
                icon: 'people',
                children: [
                    {
                        name: 'Lista',
                        icon: 'list',
                        path: '/auth/users/list',
                        children: []
                    },
                    {
                        name: 'Adicionar',
                        icon: 'add',
                        path: '/auth/users/add',
                        children: []
                    },
                ],
            },
            {
                name: 'Entidades',
                icon: 'folder',
                children: [
                    {
                        name: 'Lista', icon: 'list', path: '/auth/entity/list',
                        children: []
                    },
                    {
                        name: 'Adicionar',
                        icon: 'add',
                        path: '/auth/entity/add',
                        children: []
                    },
                    {name: 'Carregar entidades',
                        iconText: '➤',
                        path: '/auth/entity/charge/file',
                        children: []},
                ],
            },
        ],
    },

    {
        name: 'Artigos',
        icon: 'inventory',
        children: [
            {
                name: 'PIM',
                icon: 'layers',
                children: [
                    {name: 'Artigos', icon: 'list', path: '/auth/pim'},
                    {name: 'Carregar artigos', iconText: '➤', path: '/auth/pim/charge/file'},
                ],
            },
            {
                name: 'Inventário',
                icon: 'local_offer',
                children: [
                    {name: 'Localizações', icon: 'list', path: '/auth/inventory/location/list'},
                    {name: 'Stock', icon: 'list', path: '/auth/inventory/stock'},
                    {name: 'Carregar localizações', iconText: '➤', path: '/auth/inventory/location/charge/file'},
                ],
            },
        ]
    },

    {
        name: 'Gestor RH',
        icon: 'perm_contact_calendar',
        children: [
            {name: 'Picagens', iconText: '➤', path: '/auth/rh/pick'},
            {name: 'Férias', iconText: '➤', path: '/auth/rh/booking'},
            {name: 'Documentação', iconText: '➤', path: '/auth/rh/documents'},
            {name: 'Administração', iconText: '➤', path: '/auth/rh/administration'},
        ],
    },

    {
        name: 'Gestor de turnos',
        icon: 'track_changes',
        children: [
            {name: 'Turnos', iconText: '➤', path: '/auth/shift'},
        ],
    },


    {
        name: 'Tickets',
        icon: 'assignment',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/Tickets/Main'},
            {name: 'Administração', iconText: '➤', path: '/auth/Tickets/Admin'},
        ],
    },
    {
        name: 'Despesas',
        icon: 'request_quote',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/expense/main'},
            {name: 'Aprovações', iconText: '➤', path: '/auth/expense/management'},
            {name: 'Integração', iconText: '➤', path: '/auth/expense/financial'},
            {name: 'Classificação', iconText: '➤', path: '/auth/expense/classification'},
            {name: 'Fluxo', iconText: '➤', path: '/auth/expense/flow'},
            {name: 'Centro de custos', iconText: '➤', path: '/auth/expense/coastCenter'},
        ],
    },

    {
        name: 'IG',
        icon: 'menu_book',
        children: [
            {name: 'Orçamento', iconText: '➤', path: '/auth/ig/management/budget'},
            {name: 'Produção', iconText: '➤', path: '/auth/ig/management/production'},
            {name: 'Requisições', iconText: '➤', path: '/auth/ig/management/request'},
            {name: 'Calendário de produção', iconText: '➤', path: '/auth/ig/management/schedular'},
            {name: 'Controlo de produção', iconText: '➤', path: '/auth/ig/management/control'},

        ],
    },

    {
        name: 'TFP',
        icon: 'menu_book',
        children: [
            {name: 'Orçamento', iconText: '➤', path: '/auth/tfp/management/budget'},
            {name: 'Produção', iconText: '➤', path: '/auth/tfp/management/production'},
            {name: 'Calendário de produção', iconText: '➤', path: '/auth/tfp/management/schedular'},
        ],
    },

    {
        name: 'Manutenção',
        icon: 'handyman',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/maintenance'},
            {name: 'Equipamentos', iconText: '➤', path: '/auth/maintenance/entities'},
            {name: 'Definições', iconText: '➤', path: '/auth/maintenance/settings'},
        ],
    },

    {
        name: 'Documentos',
        icon: 'description',
        children: [
            {name: 'Novo', iconText: '➤', path: '/auth/doc/new'},
            {name: 'Documentos', iconText: '➤', path: '/auth/doc/docs'},
        ],
    },
    {
        name: 'Eventos',
        icon: 'description',
        children: [
            {name: 'Listar', iconText: '➤', path: '/auth/events/list'}
        ],
    },
    {
        name: 'Configurações',
        icon: 'settings',
        children: [
            {name: 'Empresas', iconText: '➤', path: '/auth/config/company/list'},
            {name: 'Departamentos', iconText: '➤', path: '/auth/config/departments'},
            {name: 'Permissões', iconText: '➤', path: '/auth/config/permitions/list'},
            {name: 'Paginas', iconText: '➤', path: '/auth/config/pages/list'},
            {name: 'Tabelas', iconText: '➤', path: '/auth/config/tables'},
            {name: 'Campos adicionais', iconText: '➤', path: '/auth/config/adicionalFields'},
            {name: 'WebHooks', iconText: '➤', path: '/auth/config/webHooks'},
            {name: 'Report', iconText: '➤', path: '/auth/config/report'},

        ],
    },

];



/*

{
        name: 'Utilizadores',
        icon: 'people',
        children: [
            {
                name: 'Lista',
                iconText: '➤',
                path: '/auth/users/list',
                children: []
            },
            {
                name: 'Adicionar',
                iconText: '➤',
                path: '/auth/users/add',
                children: []
            },
        ],
    },
    {
        name: 'Entidades',
        icon: 'folder',
        children: [
            {
                name: 'Lista', iconText: '➤', path: '/auth/entity/list',
                children: []
            },
            {
                name: 'Adicionar',
                iconText: '➤',
                path: '/auth/entity/add',
                children: []
            },
            {name: 'Carregar entidades',
                iconText: '➤',
                path: '/auth/entity/charge/file',
                children: []},
        ],
    },
    {
        name: 'Gestor RH',
        icon: 'perm_contact_calendar',
        children: [
            {name: 'Picagens', iconText: '➤', path: '/auth/rh/pick'},
            {name: 'Férias', iconText: '➤', path: '/auth/rh/booking'},
            {name: 'Documentação', iconText: '➤', path: '/auth/rh/documents'},
            {name: 'Administração', iconText: '➤', path: '/auth/rh/administration'},
        ],
    },

    {
        name: 'Gestor de turnos',
        icon: 'track_changes',
        children: [
            {name: 'Turnos', iconText: '➤', path: '/auth/shift'},
        ],
    },

    {
        name: 'PIM',
        icon: 'layers',
        children: [
            {name: 'Artigos', iconText: '➤', path: '/auth/pim'},
            {name: 'Carregar artigos', iconText: '➤', path: '/auth/pim/charge/file'},
        ],
    },
    {
        name: 'Tickets',
        icon: 'assignment',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/Tickets/Main'},
            {name: 'Administração', iconText: '➤', path: '/auth/Tickets/Admin'},
        ],
    },
    {
        name: 'Despesas',
        icon: 'request_quote',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/expense/main'},
            {name: 'Aprovações', iconText: '➤', path: '/auth/expense/management'},
            {name: 'Integração', iconText: '➤', path: '/auth/expense/financial'},
            {name: 'Classificação', iconText: '➤', path: '/auth/expense/classification'},
            {name: 'Fluxo', iconText: '➤', path: '/auth/expense/flow'},
            {name: 'Centro de custos', iconText: '➤', path: '/auth/expense/coastCenter'},
        ],
    },
    {
        name: 'Inventário',
        icon: 'local_offer',
        children: [
            {name: 'Localizações', iconText: '➤', path: '/auth/inventory/location/list'},
            {name: 'Stock', iconText: '➤', path: '/auth/inventory/stock'},
            {name: 'Carregar localizações', iconText: '➤', path: '/auth/inventory/location/charge/file'},
        ],
    },
    {
        name: 'IG',
        icon: 'menu_book',
        children: [
            {name: 'Orçamento', iconText: '➤', path: '/auth/ig/management/budget'},
            {name: 'Produção', iconText: '➤', path: '/auth/ig/management/production'},
            {name: 'Requisições', iconText: '➤', path: '/auth/ig/management/request'},
            {name: 'Calendário de produção', iconText: '➤', path: '/auth/ig/management/schedular'},
            {name: 'Controlo de produção', iconText: '➤', path: '/auth/ig/management/control'},

        ],
    },

    {
        name: 'TFP',
        icon: 'menu_book',
        children: [
            {name: 'Orçamento', iconText: '➤', path: '/auth/tfp/management/budget'},
            {name: 'Produção', iconText: '➤', path: '/auth/tfp/management/production'},
            {name: 'Calendário de produção', iconText: '➤', path: '/auth/tfp/management/schedular'},
        ],
    },

    {
        name: 'Manutenção',
        icon: 'handyman',
        children: [
            {name: 'Geral', iconText: '➤', path: '/auth/maintenance'},
            {name: 'Equipamentos', iconText: '➤', path: '/auth/maintenance/entities'},
            {name: 'Definições', iconText: '➤', path: '/auth/maintenance/settings'},
        ],
    },

    {
        name: 'Documentos',
        icon: 'description',
        children: [
            {name: 'Novo', iconText: '➤', path: '/auth/doc/new'},
            {name: 'Documentos', iconText: '➤', path: '/auth/doc/docs'},
        ],
    },
    {
        name: 'Eventos',
        icon: 'description',
        children: [
            {name: 'Listar', iconText: '➤', path: '/auth/events/list'}
        ],
    },
    {
        name: 'Configurações',
        icon: 'settings',
        children: [
            {name: 'Empresas', iconText: '➤', path: '/auth/config/company/list'},
            {name: 'Departamentos', iconText: '➤', path: '/auth/config/departments'},
            {name: 'Permissões', iconText: '➤', path: '/auth/config/permitions/list'},
            {name: 'Paginas', iconText: '➤', path: '/auth/config/pages/list'},
            {name: 'Tabelas', iconText: '➤', path: '/auth/config/tables'},
            {name: 'Campos adicionais', iconText: '➤', path: '/auth/config/adicionalFields'},
            {name: 'WebHooks', iconText: '➤', path: '/auth/config/webHooks'},
            {name: 'Report', iconText: '➤', path: '/auth/config/report'},

        ],
    },

* */
